import { messageOwnerTypes } from '../enums/messageOwnerTypes';
import { portalRoles } from '../enums/portalRoles';

export const hasBlockedCommunicationChannelForMessageSubscription = async (
  functionToCheckForBlockedCommunicationChannel,
  shownSubscription,
  recipient
) => {
  if (shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX) {
    // no block if common inbox is sender
    return false;
  }

  const creatorInstitutionProfileId = shownSubscription.mailBoxOwner.id;

  const payload = {
    creator: creatorInstitutionProfileId,
    // creatorOtpInboxId: creatorOtpInboxId,  //TODO: This will be handled in bug 244771
    profiles: [],
    groupSharings: [],
    otpInboxIds: [],
  };
  switch (recipient.mailBoxOwner.mailBoxOwnerType) {
    case messageOwnerTypes.INSTITUTION_PROFILE:
      payload.profiles = [recipient.mailBoxOwner];
      break;
    case messageOwnerTypes.OTP_INBOX:
      payload.otpInboxIds = [recipient.mailBoxOwner.id];
      break;
    case messageOwnerTypes.COMMON_INBOX:
      // no block for common inbox recipient
      return false;
    default:
      return false;
  }
  const blockedCommunicationList = await functionToCheckForBlockedCommunicationChannel(payload);
  return blockedCommunicationList.length > 0;
};

export const getNonBlockedProfiles = (recipients, blockedProfiles) =>
  recipients.filter(
    profile =>
      profile.otpInboxId == null &&
      !blockedProfiles.some(user => user.portalRole !== portalRoles.OTP && profile.id === user.institutionProfile.id)
  );

export const getNonBlockedOtps = (recipients, blockedOtps) =>
  recipients.filter(
    profile => profile.otpInboxId != null && !blockedOtps.some(otp => profile.otpInboxId === otp.otpInbox.id)
  );
